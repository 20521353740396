import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Breadcum } from '../../../../components/breadcum';
import NestedTests from '../../../../routes/content-uploads/tests-nested';


export const ContentTestInfo = () => {
    const [loading, setLoading] = useState(false);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Content',
            value: 'Content',
            routerLink: '/dashboard/content/view',
            isActive: false
        },
        {
            label: 'Test Creation (Pariksha)',
            value: 'Test Creation (Pariksha)',
            routerLink: '',
            isActive: true
        }
    ]);

    return (
        <div className="row border-top border-primary py-3">
            <Breadcum breadcumList={breadcumInfo} textLowerCase={true} margin='classic' />
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className="mt-2">
                    <ul className="nav nav-tabs custom-tabs scrolling_nav">
                        <li className="nav-item">
                            <NavLink className="nav-link" to={{ pathname: `/dashboard/content/test/test-creation`, state: { data: "" } }}>Test Creation</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={{ pathname: `/dashboard/content/test/assessment-test`, state: { data: "" } }}>Assessment Test Creation</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={{ pathname: `/dashboard/content/test/assessment-view`, state: { data: "" } }}>View Assessment Test</NavLink>
                        </li>
                    </ul>
                    <div>
                        <NestedTests />
                    </div>
                </div>
            </div>}
        </div>
    )
}

